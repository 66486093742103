require('./bootstrap');

// Enable Bootstrap tooltips
var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
	return new bootstrap.Tooltip(tooltipTriggerEl)
}); 

  
if (window.location.pathname == '/profile') {
	
	// Load CropperJS 
	window.Cropper = require('cropperjs');
	
	// Set current bio excerpt length
	$('#characterCount').html($("#bio_excerpt").val().length);
	$("#bio_excerpt").on('keyup', function() {
		$('#characterCount').html($(this).val().length);
	});
	
	
	//
	// Cropper Things
	//
	
	var uploadedFile = '',
		cropDetails = '',
		cropper;

	// Open crop modal and store the uploaded image
	window.imageChange = function(e) {

		if (typeof e.target.files[0] === 'undefined')
			return;
		
		$('#cropModal').modal('show');
		uploadedFile = URL.createObjectURL(e.target.files[0]);
	}
	
	// Crop cancel event
	window.cancelCrop = function() {
		// Clear the variables & upload element & destroy the cropper instance
		uploadedFile = '';
		cropDetails = '';
		$('#profile_image').val('');
		cropper.destroy();
	}
	
	// Crop apply event
	window.applyCrop = function() {
		// Show the image in the profile box, and apply crop transformations
		document.getElementById('profileImage').src = uploadedFile;
		
		// Calculate scale info
		var cropScale = 200 / cropDetails.width,
			cropX = cropDetails.x * cropScale,
			cropY = cropDetails.y * cropScale;
		
		// Apply transformations
		$('#profileImage').css({
			'top': '-' + cropY + 'px',
			'left': '-' + cropX + 'px',
			'transform': 'scale(' + cropScale + ')'
		});
		
		// Update crop details field
		$('#profile_image_crop_details').val(JSON.stringify(cropDetails));
		
		// Destroy cropper instance
		cropper.destroy();
	}
	
	// Initialize cropper div when crop modal loads
	$('#cropModal').on('shown.bs.modal', function () {
		
		const cropImage = document.getElementById('cropperImage');
		cropImage.src = uploadedFile;
		
		cropImage.onload = function() {
		
			cropper = new Cropper(cropImage, {
			  aspectRatio: 1 / 1,
			  crop(event) {
				cropDetails = event.detail;
			  },
			});	
			
		}
		
	});
	
	
	//
	// Vocation Things
	//
	window.addVocation = function() {
		
		if ($('.vocation').length >= 5)
			return disableAddVocations();
		
		$('<input>').attr({
			'type': 'text',
			'name': 'vocations[]',
			'id': 'vocation_' + ($('.vocation').length + 1),
			'class': 'vocation form-control mb-1',
			'list': 'vocations_list'
		}).appendTo($('#vocationsWrapper'));
		
		if ($('.vocation').length >= 5)
			return disableAddVocations();
		
	}
	
	window.disableAddVocations = function() {
		$('.addVocation').remove();
	}
	

} // end /profile scripts

if (window.location.pathname == '/admin/posts/create' || window.location.pathname.includes('/admin/posts/update')) {
	
	// Load CropperJS 
	window.Cropper = require('cropperjs');
	
	//
	// Cropper Things
	//
	
	var uploadedFile = '',
		cropDetails = '',
		cropper;

	// Open crop modal and store the uploaded image
	window.imageChange = function(e) {

		if (typeof e.target.files[0] === 'undefined')
			return;
		
		$('#cropModal').modal('show');
		uploadedFile = URL.createObjectURL(e.target.files[0]);
	}
	
	// Crop cancel event
	window.cancelCrop = function() {
		// Clear the variables & upload element & destroy the cropper instance
		uploadedFile = '';
		cropDetails = '';
		$('#post_image').val('');
		cropper.destroy();
	}
	
	// Crop apply event
	window.applyCrop = function() {
		// Show the image in the profile box, and apply crop transformations
		document.getElementById('postImage').src = uploadedFile;
		
		// Calculate scale info
		var cropScale = 500 / cropDetails.width,
			cropHeight = cropDetails.height * cropScale,
			cropX = cropDetails.x * cropScale,
			cropY = cropDetails.y * cropScale;
		
		// Apply transformations
		$('#postImageWrapper').css({
			'width': '500px',
			'height': cropHeight
		});
		$('#postImage').css({
			'top': '-' + cropY + 'px',
			'left': '-' + cropX + 'px',
			'transform': 'scale(' + cropScale + ')'
		});
		
		// Update crop details field
		$('#post_image_crop_details').val(JSON.stringify(cropDetails));
		
		// Destroy cropper instance
		cropper.destroy();
	}
	
	// Initialize cropper div when crop modal loads
	$('#cropModal').on('shown.bs.modal', function () {
		
		const cropImage = document.getElementById('cropperImage');
		cropImage.src = uploadedFile;
		
		cropImage.onload = function() {
		
			cropper = new Cropper(cropImage, {
			  crop(event) {
				cropDetails = event.detail;
			  },
			});	
			
		}
		
	});
	
	// Update the post slug when post title changes
	window.updateSlug = function() {
			
		var postTitle = $('#post_title').val();
		const regexMatch = /[^A-Za-z0-9\-]/g;
		
		postTitle = postTitle.replaceAll(' ', '-').replace(regexMatch, '').replaceAll('--', '-').toLowerCase();

		$('#post_slug').val(postTitle);
		
	}
	

} // end /admin/posts/create scripts


if (window.location.pathname == '/admin/vocations') {
	
	var editModal = document.getElementById('editModal');
	editModal.addEventListener('show.bs.modal', function (event) {
		
		// The button that triggered the modal
		var button = event.relatedTarget;
		
		// Extract info from data-bs-* attributes
		var title = button.getAttribute('data-bs-voctitle');
		var id = button.getAttribute('data-bs-vocid');
		var users = JSON.parse(button.getAttribute('data-bs-users'));
		var url = button.getAttribute('data-bs-update-url');
		
		// Set the field attributes
		editModal.querySelector('#vocID').value = id;
		editModal.querySelector('#vocName').value = title;
		editModal.querySelector('#users').innerHTML = '';	
		editModal.querySelector('#vocURL').value = url;
			
		for (var k in users) {
			var userEle = document.createElement('div');
			userEle.innerHTML = users[k].last_name + ', ' + users[k].name;
		    editModal.querySelector('#users').append(userEle);
		}
		
	});
	
} // end /admin/vocations scripts


if (window.location.pathname == '/forum/new') {
	
	// Load CropperJS 
	window.Cropper = require('cropperjs');
	
	//
	// Cropper Things
	//
	
	var uploadedFile = '',
		cropDetails = '',
		cropper;

	// Open crop modal and store the uploaded image
	window.imageChange = function(e) {

		if (typeof e.target.files[0] === 'undefined')
			return;
		
		$('#cropModal').modal('show');
		uploadedFile = URL.createObjectURL(e.target.files[0]);
	}
	
	// Clear the current uploaded photo	
	window.clearUpload = function() {
		uploadedFile = '';
		cropDetails = '';
		$('#post_image_crop_details').val('');
		$('#post_image').val('');
		$('#postImageControls').show();
		$('#clearUpload').hide();
		document.getElementById('postImage').src = '';
		
		$('#postImageWrapper').css({
			'height': ''
		});
		$('#postImage').css({
			'top': '',
			'left': '',
			'transform': ''
		});
	}
	
	// Crop cancel event
	window.cancelCrop = function() {
		// Clear the variables & upload element & destroy the cropper instance
		uploadedFile = '';
		cropDetails = '';
		$('#post_image').val('');
		cropper.destroy();
	}
	
	// Crop apply event
	window.applyCrop = function() {
		// Show the image in the profile box, and apply crop transformations
		document.getElementById('postImage').src = uploadedFile;
		
		// Calculate scale info
		var cropScale = 500 / cropDetails.width,
			cropHeight = cropDetails.height * cropScale,
			cropX = cropDetails.x * cropScale,
			cropY = cropDetails.y * cropScale;
		
		// Apply transformations
		var containerScale = $('#postImageWrapper').innerWidth() / 500;
		
		$('#postImageWrapper').css({
			'height': cropHeight * containerScale
		});
		$('#postImage').css({
			'top': '-' + cropY * containerScale + 'px',
			'left': '-' + cropX * containerScale + 'px',
			'transform': 'scale(' + (cropScale * containerScale) + ')'
		});
		
		// Update crop details field
		$('#post_image_crop_details').val(JSON.stringify(cropDetails));
		
		// Hide the image controls
		$('#postImageControls').hide();
		
		// Show the cancel image control
		$('#clearUpload').show();
		
		// Destroy cropper instance
		cropper.destroy();
	}
	
	// Initialize cropper div when crop modal loads
	$('#cropModal').on('shown.bs.modal', function () {
		
		const cropImage = document.getElementById('cropperImage');
		cropImage.src = uploadedFile;
		
		cropImage.onload = function() {
		
			cropper = new Cropper(cropImage, {
			  crop(event) {
				cropDetails = event.detail;
			  },
			});	
			
		}
		
	});


} // end /admin/posts/create scripts